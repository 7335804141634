import { Link } from "@remix-run/react"

export default function FooterIndex() {
    return (
        <div className="tw-relative tw-mt-12 tw-bg-teal-900 tw-text-teal-100 tw-py-8 tw-ring-4 tw-ring-gray-900/5 sm:mx-auto tw-max-w-4xl tw-rounded-lg tw-px-10 sm:tw-mx-2">
            <div className="tw-mx-auto tw-max-w-2xl">
                <div className="tw-flex tw-items-start tw-justify-between sm:tw-flex-col md:tw-flex-row  md:tw-space-x-36">
                    <div className="tw-flex tw-flex-col tw-items-start tw-space-y-1 tw-leading-7">
                        <Link to="/"><div className="tw-font-bold tw-text-2xl">PricePitch</div></Link>
                        <div className="tw-grow tw-h-5"></div>
                        <div className="tw-text-sm">
                            PricePitch® by MVPs Dev Studio, part of TechOps.
                            Learn more at <a className="hover:tw-text-teal-200 " href="https://mvps.dev">MVPs.Dev</a>
                            {" © " + new Date().getFullYear()} TechOps SMC PVT LTD. All rights reserved.
                        </div>
                        <div className="sm:tw-grow sm:tw-h-5 md:tw-hidden"></div>
                    </div>
                    <div className="tw-flex tw-flex-col tw-items-start tw-space-y-1 tw-leading-7">
                        <div className="tw-font-bold tw-text-2xl">Resources</div>
                        <div className="tw-grow tw-h-5"></div>
                        <div className="tw-text-sm">
                            <Link to={"/privacy"} className="hover:tw-text-teal-200">Privacy</Link>
                        </div>
                        <div className="tw-text-sm">
                            Contact <a className="hover:tw-text-teal-200" href="mailto:ask@pricepitch.pro">ask@pricepitch.pro</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
